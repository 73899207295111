import React from 'react';
import './SuggestionPopup.css';
import SpinningLogo from './SpinningLogo';
export interface SuggestionPopupProps {
    popupHider: () => void,
    requestCounter: () => number
}

export interface SuggestionPopupState {
  popupTitle: string,
  popupDesc: string,
  loading: boolean,
        myRef: any,
        messageIdx: number
}

const uploadingMessages: string[] = ["Uploading to the cloud...", "Inscribing into the ether...", "Whispering into the darkness...", "Writing that one down...", "Committing your request to memory...", "Notarizing in triplicate...", "Enshrining your request...", "Jotting that one for later...", "Documenting for future crimes...", "Adding to the docket...", "Cataloging for future use...", "Charting a course...", "Registering to the data banks..."];

export class SuggestionPopup extends React.Component<SuggestionPopupProps, SuggestionPopupState> {
  constructor(props: SuggestionPopupProps) {
    super(props);
    this.state = {
      popupTitle: "Thanks for submitting a request!",
      popupDesc: "",
      loading: true,
        myRef: React.createRef(),
        messageIdx: Math.floor(Math.random() * uploadingMessages.length)
    };
    this.finishLoading = this.finishLoading.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.finishLoading(), 2200);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <>
        <div className="SuggestionPopup" ref={this.state.myRef}>
          {this.state.loading && <h1>{uploadingMessages[this.state.messageIdx]}</h1>}
          {!this.state.loading && <h1>{this.state.popupTitle}</h1> }
          {this.state.loading && <SpinningLogo />}
          {!this.state.loading &&
          <>
            <h2>ligma balls</h2>
            <h2>Recent requests: {this.props.requestCounter()}</h2>
            <button className="SuggestionPopup-button" onClick={() => this.props.popupHider()}>Close</button>
          </>
          }
        </div>
    </>
    );
  }

  finishLoading() {
    this.setState({loading: false});
  }

  handleClickOutside(event) {
    if (!this.state.loading && this.state.myRef && !this.state.myRef.current.contains(event.target)) {
      this.props.popupHider();
    }
  }


}
