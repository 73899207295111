import React from 'react';
import SpinningLogo from './components/SpinningLogo';
import { Episode, EpisodeProps } from './components/Episode';
import { SuggestionBox } from './components/SuggestionBox';
import { SuggestionPopup } from './components/SuggestionPopup';
import './Arthouse.css';

interface ArthouseProps {

}
interface ArthouseState {
  onDeck: EpisodeProps,
  season0: EpisodeProps[],
  season1: EpisodeProps[],
  season2: EpisodeProps[],
  season3: EpisodeProps[],
  season4: EpisodeProps[],
  securityToken: string,
  loading: boolean,
  selectedSeason: number,
  showPopup: boolean,
  requestsSoFar: number
}

export default class Arthouse extends React.Component<ArthouseProps, ArthouseState> {
  constructor(props) {
    super(props);
    this.state = {
        onDeck: {
          fileName: 'blahblah.mkv',
          season: '3',
          title: 'placeholder',
          description: '',
          securityToken: '',
          separateSubs: true,
        },
        securityToken: '',
        loading: true,
        selectedSeason: 0,
        showPopup: false,
        season0: [],
        season1: [],
        season2: [],
        season3: [],
        season4: [],
        requestsSoFar: 0
    };

    this.selectSeasonClick = this.selectSeasonClick.bind(this);
    this.selectPreviousSeason = this.selectPreviousSeason.bind(this);
    this.selectNextSeason = this.selectNextSeason.bind(this);
    this.refetchAuthToken = this.refetchAuthToken.bind(this);
    this.submitSuggestion = this.submitSuggestion.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.getRequestsSoFar = this.getRequestsSoFar.bind(this);
  }
  async componentDidMount() {
    document.title = "Arthouse Archive";
    const resp = await fetch('https://arthouse.aaron-friesen.com/content');
    const flatContent = await resp.json();
    var seasons: EpisodeProps[][] = [[],[],[],[],[]];
    flatContent.forEach(episode => {
      seasons[+ episode.season].push(episode);
    });
    for (let idx in seasons) {
      seasons[idx].sort((a, b) => a.fileName.localeCompare(b.fileName));
    }
    let currentSeason = seasons[0];
    this.setState({
        onDeck: currentSeason[currentSeason.length - 1],
        loading: false,
        season0: seasons[0],
        season1: seasons[1],
        season2: seasons[2],
        season3: seasons[3],
        season4: seasons[4]
    });

    await this.refetchAuthToken();
  }

  render() {
    let selectedSeasonX = [
      this.state.selectedSeason === 0 ? "#FFA500" : "#1d1f24",
      this.state.selectedSeason === 1 ? "#FFA500" : "#1d1f24",
      this.state.selectedSeason === 2 ? "#FFA500" : "#1d1f24",
      this.state.selectedSeason === 3 ? "#FFA500" : "#1d1f24",
      this.state.selectedSeason === 4 ? "#FFA500" : "#1d1f24"
    ];
    return (
      <div className="Arthouse">
          {this.state.loading &&
          <>
              <SpinningLogo />
              <p>
                Arthouse is loading...
              </p>
          </>
          }
          {!this.state.loading &&
              <>
                <h1>Welcome to the Arthouse Archives!</h1>
                <h4>On Deck:</h4>
                <Episode
                    fileName={this.state.onDeck.fileName}
                    season={this.state.onDeck.season}
                    title={this.state.onDeck.title}
                    description={this.state.onDeck.description}
                    securityToken={this.state.securityToken}
                    separateSubs={this.state.onDeck.separateSubs}
                />
                <br />
                <div className="Arthouse-season-picker">
                    <p onClick={() => this.selectSeasonClick(0)} style={{'backgroundColor': selectedSeasonX[this.state.selectedSeason]}}>Intermissions</p>
                    <p onClick={() => this.selectSeasonClick(1)} style={{'backgroundColor': selectedSeasonX[this.state.selectedSeason]}}>Season 1</p>
                    <p onClick={() => this.selectSeasonClick(2)} style={{'backgroundColor': selectedSeasonX[this.state.selectedSeason]}}>Season 2</p>
                    <p onClick={() => this.selectSeasonClick(3)} style={{'backgroundColor': selectedSeasonX[this.state.selectedSeason]}}>Season 3</p>
                    <p onClick={() => this.selectSeasonClick(4)} style={{'backgroundColor': selectedSeasonX[this.state.selectedSeason]}}>Season 4</p>
                </div>
                {this.state.selectedSeason === 0 &&
                <div className="Arthouse-season">
                    {this.state.season0.map((episode) => <> <Episode fileName={episode.fileName} season={episode.season} title={episode.title} description={episode.description} securityToken={this.state.securityToken} separateSubs={episode.separateSubs}/> <br /> </>)}
                </div>
                }
                {this.state.selectedSeason === 1 &&
                <div className="Arthouse-season">
                    {this.state.season1.map((episode) => <> <Episode fileName={episode.fileName} season={episode.season} title={episode.title} description={episode.description} securityToken={this.state.securityToken} separateSubs={episode.separateSubs}/> <br /> </>)}
                </div>
                }
                {this.state.selectedSeason === 2 &&
                <div className="Arthouse-season">
                    {this.state.season2.map((episode) => <> <Episode fileName={episode.fileName} season={episode.season} title={episode.title} description={episode.description} securityToken={this.state.securityToken} separateSubs={episode.separateSubs} /> <br /> </>)}
                </div>
                }
                {this.state.selectedSeason === 3 &&
                <div className="Arthouse-season">
                    {this.state.season3.map((episode) => <> <Episode fileName={episode.fileName} season={episode.season} title={episode.title} description={episode.description} securityToken={this.state.securityToken} separateSubs={episode.separateSubs} /> <br /> </>)}
                </div>
                }
                {this.state.selectedSeason === 4 &&
                <div className="Arthouse-season">
                    {this.state.season4.map((episode) => <> <Episode fileName={episode.fileName} season={episode.season} title={episode.title} description={episode.description} securityToken={this.state.securityToken} separateSubs={episode.separateSubs} /> <br /> </>)}
                </div>
                }
                <div className="Arthouse-season-picker">
                  {this.state.selectedSeason !== 0 &&
                    <p onClick={this.selectPreviousSeason}>Previous Season</p>
                  }
                  {this.state.selectedSeason !== 4 &&
                    <p onClick={this.selectNextSeason}>Next Season</p>
                  }
                </div>
                <SuggestionBox submitSuggestion={this.submitSuggestion} />
                {this.state.showPopup && <SuggestionPopup popupHider={this.hidePopup} requestCounter={this.getRequestsSoFar}/>}
              </>
          }
      </div>
    );
  }

  selectSeasonClick(season) {
    this.setState({selectedSeason: season});
    this.refetchAuthToken();
  }
  selectPreviousSeason() {
    this.setState((prevState, props) => {
        return {
          selectedSeason: prevState.selectedSeason - 1,
        };
    });
    this.refetchAuthToken();
  }
  selectNextSeason() {
    this.setState((prevState, props) => {
      return {
        selectedSeason: prevState.selectedSeason + 1,
      };
    });
    this.refetchAuthToken();
  }

  async submitSuggestion(title, desc) {
    console.log('Showing popup');
    this.setState({showPopup: true});
    const content = JSON.stringify({title: title, details: desc});
    const response = await fetch('https://arthouse.aaron-friesen.com/request', {
      method: 'POST',
      body: content,
      headers: {'Content-Type': 'application/json' }
    });
    const responseJson = await response.json();
    console.log(responseJson);
    console.log('Updating popup');
    this.setState({requestsSoFar: responseJson.requestCount});
  }

  hidePopup() {
    console.log('Hiding popup');
    this.setState({showPopup: false});
  }

  getRequestsSoFar() {
    return this.state.requestsSoFar;
  }

  async refetchAuthToken() {
    const tokenResp = await fetch('https://arthouse.aaron-friesen.com/token');
    const token = await tokenResp.json();
    this.setState({securityToken: token.id});
  }
}
