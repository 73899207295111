import React from 'react';
import './SuggestionBox.css';
export interface SuggestionBoxProps {
  submitSuggestion: (title: string, desc: string) => void
}

export interface SuggestionBoxState {
  hidden: boolean,
  suggestionTitle: string,
        suggestionDesc: string,
        wrapperRef: any
}

const hiddenText = "Have a suggestion/request?";
const shownText = "X";

export class SuggestionBox extends React.Component<SuggestionBoxProps, SuggestionBoxState> {

  constructor(props: SuggestionBoxProps) {
    super(props);
    this.state = {
      hidden: true,
      suggestionTitle: '',
        suggestionDesc: '',
        wrapperRef: React.createRef()
    };

    this.titleChange = this.titleChange.bind(this);
    this.descChange = this.descChange.bind(this);
    this.submitKey = this.submitKey.bind(this);
    this.toggleHidden = this.toggleHidden.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <>
        <div className="SuggestionBox" ref={this.state.wrapperRef}>
          <div className={this.state.hidden ? "SuggestionBox-content-contracted" : "SuggestionBox-content-expanded"}>
            <div className="SuggestionBox-button-box">
              {!this.state.hidden &&
                <div className="SuggestionBox-button-spacer" />
              }
              <button className={this.state.hidden ? "SuggestionBox-hide-button-hidden" : "SuggestionBox-hide-button-visible"} onClick={this.toggleHidden}>{this.state.hidden ? hiddenText : shownText}</button>
            </div>
            {!this.state.hidden && <textarea className="SuggestionBox-title" value={this.state.suggestionTitle} onChange={this.titleChange} onKeyPress={this.submitKey} placeholder={"Suggestion Title"} /> }
            {!this.state.hidden && <div className="SuggestionBox-one-space" /> }
            {!this.state.hidden && <textarea className="SuggestionBox-details" value={this.state.suggestionDesc} onChange={this.descChange} onKeyPress={this.submitKey} placeholder={"Additional Thoughts (Optional)"} /> }
        {!this.state.hidden && <button className="SuggestionBox-submit-button" onClick={() => this.submit() } disabled={this.state.suggestionTitle === ''}>Submit Suggestion</button> }
          </div>
        </div>
      </>
    );

  }



  titleChange(event) {
    this.setState({suggestionTitle: event.target.value});
  }

  descChange(event) {
    this.setState({suggestionDesc: event.target.value});
  }


  submitKey(event) {
    if (event.key === 'Enter') {
        this.submit();
    }
  }

  submit() {
    this.props.submitSuggestion(this.state.suggestionTitle, this.state.suggestionDesc);
    this.setState({
      suggestionTitle: '',
      suggestionDesc: ''
    });
    this.toggleHidden(null);
  }

  handleClickOutside(event) {
    if (!this.state.hidden && this.state.wrapperRef && !this.state.wrapperRef.current.contains(event.target)) {
      this.toggleHidden(null);
    }
  }

  toggleHidden(event) {
    this.setState((state, props) => {
      return {hidden: !state.hidden};
    });
  };
}
