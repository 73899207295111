import React from 'react';
import Arthouse from './Arthouse';
import HomePage from './components/HomePage';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/arthouse">
          <Arthouse />
        </Route>
        <Route path="/boardgames" render={() => window.location.href = "https://quick-shade-119.notion.site/Goard-Games-3e47c97bf9da4590857c49ceb7c54912" }/>
        <Route path="/frosthaven" render={() => window.location.href = "https://quick-shade-119.notion.site/Scenario-Log-1cdb1766fde64b3793b209f73d21c282" }/>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>

    </Router>
  );
}

export default App;
