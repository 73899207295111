import React from 'react';
import orange from '../orange.svg';
import './SpinningLogo.css';

class SpinningLogo extends React.Component {

  render() {
    return (
      <img src={orange} className="SpinningLogo" alt="I like oranges." />
    );
  }
}

export default SpinningLogo;
