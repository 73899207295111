import React from 'react';
import SpinningLogo from './SpinningLogo';
import './HomePage.css';

class HomePage extends React.Component {
    render() {
        return (
          <div className="HomePage">
            <div className="HomePage-content">
              <SpinningLogo />
              <p>
                There was a website here. It's gone now. I'll replace it... eventually.
              </p>
              <br />
                <p>
                    For business inquiries in the meantime, feel free to reach out to me at aaron@aaron-friesen.com.
                </p>
            </div>
          </div>
        )
    }
}

export default HomePage;
