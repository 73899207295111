import React from 'react';
import './Episode.css';
import downloadIcon from '../download-icon.png';

export interface EpisodeProps {
  fileName: string,
  season: string,
  title: string,
  description: string,
  securityToken: string,
  separateSubs: boolean
}

interface EpisodeState {
  titleShown: boolean,
    password: string,
    isMobile: boolean
}

export class Episode extends React.Component<EpisodeProps, EpisodeState> {
    constructor(props: EpisodeProps) {
        super(props);
        this.state = {
          titleShown: false,
          password: '',
          isMobile: false
        };
        this.passwordChange = this.passwordChange.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.downloadSubs = this.downloadSubs.bind(this);
        this.flipTitle = this.flipTitle.bind(this);
        this.passwordKey = this.passwordKey.bind(this);
        this.updateScreenSize = this.updateScreenSize.bind(this);
    }

    componentDidMount() {
      this.updateScreenSize();
      window.addEventListener("resize", this.updateScreenSize);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateScreenSize);
    }

    render() {
      return (
        <div className="Episode">
          <div className="Episode-number-and-title">
              <p className="Episode-number">{this.episodeNameWithoutExtension()}</p>
              <p className={this.state.titleShown ? "Episode-title-shown" : "Episode-title-hidden"} onClick={this.flipTitle}>{this.props.title}</p>
              <div className="Episode-title-buffer" />
          </div>
          <div className="Episode-non-buttons">
              <div className="Episode-left">
                <div className="Episode-image">
                    <img src={this.episodeImageUrl()} />
                </div>
              </div>
              <div className="Episode-right">
                <div className="Episode-description">
                    {
                      this.props.description.split('\n').map((item, idx) => {
                        return (
                          <span key={idx}>
                            {item}
                            <br/>
                          </span>
                        );
                    })
    }
                </div>
              </div>
            </div>
            <div className="Episode-buttons">
                <input type="text" value={this.state.password} onChange={this.passwordChange} onKeyPress={this.passwordKey} placeholder={this.state.isMobile ? "Password" : "Insert Password Here"} />
                {this.state.isMobile ? (
                    <button className="Episode-download-button" onClick={this.downloadFile}>
                        <img className="Episode-download-icon" src={downloadIcon} alt="Download" />
                    </button>

                ) : (
                    <button className="Episode-download-button" onClick={this.downloadFile}>
                        Download
                    </button>
                )}
                {this.state.isMobile ?
                    this.props.separateSubs ? (
                        <button className="Episode-subs-button" onClick={this.downloadSubs} disabled={!this.props.separateSubs}>
                          Subs
                          <img className="Episode-download-icon" src={downloadIcon} alt="Download" />
                        </button>
                    ) : (
                        <button className="Episode-subs-button" onClick={this.downloadSubs} disabled={!this.props.separateSubs}>
                          Subs inc.
                        </button>
                    ) :
                    this.props.separateSubs ? (
                        <button className="Episode-subs-button" onClick={this.downloadSubs} disabled={!this.props.separateSubs}>Subs</button>
                    ) : (
                        <button className="Episode-subs-button" onClick={this.downloadSubs} disabled={!this.props.separateSubs}>Subs included</button>
                    )
                }
            </div>
        </div>
      );
    }

  episodeImageUrl() {
    let baseUrl = "https://arthouse-archive.s3-us-west-2.amazonaws.com/season-";
    return baseUrl + this.props.season + "/" + this.episodeNameWithoutExtension() + ".png";
  }

  episodeNameWithoutExtension() {
    if (this.props.fileName.indexOf(".") === -1) {
      return this.props.fileName;
    }
    let split = this.props.fileName.split(".");
    if (split.length === 2) {
      return split[0];
    } else {
      var fileName = '';
      for(var i = 0; i < split.length - 1; i++) {
        if (i === 0)
          fileName += split[i];
        else
          fileName += "." + split[i];
      }
      return fileName;
    }
  }

  subsName() {
    return this.episodeNameWithoutExtension() + '.srt';
  }

  passwordKey(event) {
    if (event.key === 'Enter') {
      this.downloadFile();
    }
  }

  passwordChange(event) {
    this.setState({password: event.target.value});
  }

  flipTitle() {
    this.setState((state, props) => {
      return {titleShown: !state.titleShown};
    });
  }

  updateScreenSize() {
    this.setState({ isMobile: window.innerWidth < 700 });
  }

  async downloadFile() {
    console.log(`Attempted to download ${this.props.fileName} with password ${this.state.password} and token ${this.props.securityToken}`);
    let apiUrl = `https://arthouse.aaron-friesen.com/download?downloadTarget=season-${this.props.season}/${this.props.fileName}&password=${this.state.password}`;
    var a = document.createElement('a');
    a.href = apiUrl || 'https://google.com';
    a.setAttribute('download', this.props.fileName);
    a.setAttribute('id', 'temporary-id');
    a.click();
    var element = document.getElementById('temporary-id');
    if (element !== null) {
      element!.parentNode!.removeChild(element);
    }
  }

  async downloadSubs() {
    console.log(`Attempted to download subs for ${this.props.fileName} with password ${this.state.password} and token ${this.props.securityToken}`);
    let apiUrl = `https://arthouse.aaron-friesen.com/download?downloadTarget=season-${this.props.season}/${this.episodeNameWithoutExtension()}.srt&password=${this.state.password}`;
    var a = document.createElement('a');
    a.href = apiUrl || 'https://google.com';
    a.setAttribute('download', this.props.fileName);
    a.setAttribute('id', 'temporary-id');
    a.click();
    var element = document.getElementById('temporary-id');
    if (element !== null) {
      element!.parentNode!.removeChild(element);
    }
  }
}
